import React from 'react';

function CatList({ cat }) {
  return (
    <tr className='catList-list'>
      <td width='100%' className='list-name'>
        <p>{cat.name}</p>
      </td>
    </tr>
  );
}

export default CatList;
