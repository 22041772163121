import React, { useEffect, useState } from 'react';
import Topnav from '../../Includes/Topnav/Topnav';
import Footer from '../../Includes/Footer/Footer';
import moment from 'moment';
import FeaturedPostsList from '../../components/Post/FeaturedPostsList/FeaturedPostsList';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { BsEyeglasses } from 'react-icons/bs';
import './Post.css';
import Newsletter from '../../components/Newsletter/Newsletter';
import { useLocation, Link } from 'react-router-dom';
import { Axios } from '../../config';

function Post() {
  const location = useLocation();

  const postID = location.pathname.split('/')[3];

  const [post, setPost] = useState(null);

  useEffect(() => {
    const getPost = async () => {
      const res = await Axios.get(`posts/${postID}`);
      setPost(res.data);
    };
    getPost();
  }, [postID]);

  function readingPostTime() {
    const text = post && post.desc;
    const wpm = 225;
    const words = text && text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);

    return time;
  }

  const readTime = readingPostTime();

  // const publicFolder = 'http://localhost:5000/images/';
  const publicFolder = 'https://api-blog.damina.org/images/';

  // if (post && post.keys > 0) {
  //   console.log('hello there the object is full');
  // }

  return (
    <>
      <Topnav />
      <div className='container'>
        <div className='target-post'>
          <div className='post-column'>
            <div className='post-column-meta'>
              <div className='post-column-category'>
                <div>
                  {post &&
                    post.categories.slice(0, 4).map((cat, index) => (
                      <span key={index} className='cat-list'>
                        {cat.value}
                      </span>
                    ))}
                </div>

                <span>
                  <BsEyeglasses /> <p>{readTime} min read</p>
                </span>
              </div>

              <h1 className='post-column-title'>{post && post.title}</h1>

              <div className='post-column-author'>
                <p>
                  By
                  <span>
                    {/* <Link to={`/blog/?username=${post && post.username}`}> */}
                    <Link to={`/blog/?username=${post && post.username}`}>
                      {post && post.username}
                    </Link>
                  </span>
                  - {moment(post && post.updatedAt).format('MMMM Do YYYY')}
                </p>
              </div>
            </div>

            <div className='post-column-image'>
              {post && (
                <img
                  src={publicFolder + post.photo}
                  alt=''
                />
              )}
            </div>

            <div className='post-column-content'>
              {post && (
                <Editor
                  editorState={
                    post &&
                    EditorState.createWithContent(
                      convertFromRaw(JSON.parse(post && post.desc))
                    )
                  }
                  readOnly={true}
                />
              )}
            </div>
          </div>

          <div className='featuredPost-column'>
            <FeaturedPostsList />
          </div>
        </div>
        <Newsletter />
      </div>
      <div className='container'>
        <Footer />
      </div>
    </>
  );
}

export default Post;
