import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { Axios } from '../../../config';
import { FaImage } from 'react-icons/fa';
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';

import '../AddBlog/AddBlog.css';
import '../AddBlog/RichText.css';
import '../../../../node_modules/draft-js/dist/Draft.css';
import { Context } from '../../../context/Context';
import RichEditorExample from '../AddBlog/RichText';

function EditBlog({ cats, blogEditID }) {
  const { user } = useContext(Context);
  const [title, setTitle] = useState(''); // title
  const [featured, setFeatured] = useState(false); // featured
  const [selectedStatus, setSelectedStatus] = useState('Draft'); // Draft - Published
  const [selectedCategories, setSelectedCategories] = useState(null); // selected categories
  const [file, setFile] = useState(null);
  const [pic, setPic] = useState(null);
  const [myeditorState, seteditorState] = useState({
    editorState: EditorState.createEmpty(),
  });

  // ***************************************

  useEffect(() => {
    const getBlogPost = async () => {
      try {
        const post = await Axios.get(`/posts/${blogEditID}`);
        setTitle(post.data.title);
        setFeatured(post.data.featured === 'true' ? true : false);
        setSelectedStatus(post.data.status);
        setSelectedCategories(post.data.categories);
        setPic(post.data.photo);
        seteditorState({
          editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(post.data.desc))
          ),
        });
      } catch (err) {
        console.log(err);
        alert('There was an error while retrieving the data');
      }
    };

    getBlogPost();
  }, [blogEditID]);

  // ***************************************

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleFeatured = () => {
    setFeatured(!featured);
  };

  const selectedStatusOption = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    const { editorState } = myeditorState;
    const data = editorState.getCurrentContent();
    const content = JSON.stringify(convertToRaw(data));

    const newPost = {
      'title': title,
      'desc': content,
      'status': selectedStatus,
      'featured': featured,
      'username': user.username,
      'categories': selectedCategories,
    };

    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append('name', filename);
      data.append('file', file);
      newPost.photo = filename;
      try {
        await Axios.post('/upload', data);
      } catch (err) {}
    }
    // console.log(newPost);

    try {
      const res = await Axios.patch(`/posts/${blogEditID}`, newPost);

      // window.location.replace(
      //   'http://localhost:3000/blog/post/' + res.data._id
      // );

      // window.open('http://localhost:3000/blog/post/' + res.data._id, '_blank');
      window.open(
        'https://news.damina.org/blog/post/' + res.data._id,
        '_blank'
      );
    } catch (err) {}

    // setTitle('');
    // setFeatured(false);
    // setSelectedStatus('Draft');
    // setSelectedCategories(null);
    // setFile(null);
    // setPic(null);
    // seteditorState({
    //   editorState: EditorState.createEmpty(),
    // });
  };

  const publicFolder = 'https://api-blog.damina.org/images/';

  let myfile;

  if (file === null) {
    myfile = `${publicFolder + pic}`;
  } else {
    myfile = `${URL.createObjectURL(file)}`;
  }

  return (
    <div>
      <div className='manage-blog'>
        <div className='manage-blog-title'>Edit Blog</div>
        <form className='addblog' onSubmit={handlesubmit}>
          <div className='title'>
            <span className='title-span'>
              <label htmlFor='posttitle'>Title</label>
              <input
                type='text'
                name='title'
                id='posttitle'
                value={title}
                onChange={(e) => handleTitle(e)}
              />
            </span>
            <span className='featured-span'>
              <label htmlFor='featured-post'>Featured</label>
              <input
                type='checkbox'
                name='featured'
                id='featured-post'
                checked={featured}
                onChange={handleFeatured}
              />
            </span>
          </div>
          <div className='featuredImage'>
            <label htmlFor='postImage'>Featured Image</label>
            <div className='featured-Pic'>
              {/* {file === null ? (
                <img src={publicFolder + pic} alt='' />
              ) : (
                <img src={URL.createObjectURL(file)} alt='' />
              )} */}

              {pic && <img src={myfile} alt='' />}

              <label htmlFor='fileInput'>
                <i className='featured-PicIcon'>
                  <FaImage />
                </i>
              </label>
              <input
                type='file'
                id='fileInput'
                style={{ display: 'none' }}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
          </div>
          <div className='stat-cat'>
            <div className='cat'>
              <p>Category</p>
              <Select
                value={selectedCategories}
                onChange={setSelectedCategories}
                options={cats}
                isMulti
              />
            </div>
            <div className='stat'>
              <p>Status</p>
              <div className='selectStatus'>
                <label htmlFor='Draft'>Draft</label>
                <input
                  type='radio'
                  name='status'
                  id='Draft'
                  value='Draft'
                  className='custom-radio'
                  checked={selectedStatus === 'Draft'}
                  onChange={selectedStatusOption}
                />
                <label htmlFor='Published'>Published</label>
                <input
                  className='custom-radio'
                  id='Draft'
                  type='radio'
                  name='status'
                  value='Published'
                  checked={selectedStatus === 'Published'}
                  onChange={selectedStatusOption}
                />
              </div>
            </div>
          </div>
          <div className='blog-content'>
            {/* ************************************************************************************************************* */}

            {/* ************************************************************************************************************* */}
            <RichEditorExample
              Editor={Editor}
              EditorState={EditorState}
              RichUtils={RichUtils}
              getDefaultKeyBinding={getDefaultKeyBinding}
              myeditorState={myeditorState}
              seteditorState={seteditorState}
            />
          </div>
          <button type='submit' className='button'>
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditBlog;
