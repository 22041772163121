import React, { useState } from 'react';
import { Axios } from '../../../config';
function AddCategory() {
  const [category, setCategory] = useState('');
  const [success, setSuccess] = useState(false);

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    let str =
      category.charAt(0).toUpperCase() + category.slice(1).toLowerCase();

    // ********
    //       const str = 'flexiple';
    // const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    // console.log(str2);

    //Output: Flexiple

    // const str = 'abc efg';
    // const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    // console.log(str2);

    //Output: Abc efg
    // ********

    const newCategory = {
      'name': str,
    };

    try {
      await Axios.post('/category', newCategory);
      setSuccess(true);
    } catch (err) {}

    setCategory('');
  };

  return (
    <div>
      <div className='manage-blog'>
        <div className='manage-blog-title'>Add Category</div>
        <form className='addCat' onSubmit={handlesubmit}>
          <div>
            <label htmlFor='catInput'>Category</label>
            <input
              type='text'
              name='category'
              className='postCat'
              id='catInput'
              value={category}
              onChange={(e) => handleCategory(e)}
            />
          </div>
          <button type='submit' className='button'>
            Save
          </button>
        </form>
      </div>
      {success && (
        <span style={{ color: 'green', textAlign: 'center' }}>
          Category Saved Sccessfully
        </span>
      )}
    </div>
  );
}

export default AddCategory;
