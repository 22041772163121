import React, { useRef, useContext } from 'react';
import Topnav from '../../../Includes/Topnav/Topnav';
import Footer from '../../../Includes/Footer/Footer';

import './Login.css';
import { Context } from '../../../context/Context';
import { Axios } from '../../../config';
// import { useNavigate } from 'react-router-dom';

function Login() {
  const userRef = useRef();
  const passwordRef = useRef();

  const { dispatch, isFetching } = useContext(Context);

  // let navigate = useNavigate();

  // const routeChange = () => {
  //   console.log('hello');
  //   let path = '/blog/v1/dashboard';
  //   navigate(path);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: 'LOGIN_START' });

    try {
      const res = await Axios.post('/auth/login', {
        username: userRef.current.value,
        password: passwordRef.current.value,
      });

      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });

      // window.location.replace('http://localhost:3000/blog/v1/dashboard');
      window.location.replace('https://news.damina.org/blog/v1/dashboard');

      // routeChange();
    } catch (err) {
      dispatch({ type: 'LOGIN_FAILURE' });
    }
  };

  return (
    <div>
      <Topnav />
      <div className='container'>
        <div className='login'>
          <div className='login-form'>
            <form onSubmit={handleSubmit}>
              <label htmlFor='username'>Username</label>
              <input type='text' name='username' id='username' ref={userRef} />
              <label htmlFor='password'>Password</label>
              <input
                type='password'
                name='password'
                id='password'
                ref={passwordRef}
              />
              <button type='submit' disabled={isFetching}>
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className='container'>
        <Footer />
      </div>
    </div>
  );
}

export default Login;
