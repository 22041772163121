import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { FaUserEdit, FaTrashAlt } from 'react-icons/fa';
import { Axios } from '../../../config';
import { Context } from '../../../context/Context';
import './Settings.css';

function Settings() {
  const { user } = useContext(Context);
  const [file, setFile] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [pic, setPic] = useState(null);

  useEffect(() => {
    const getuserInfo = async () => {
      const res = await Axios.get(`/users/${user._id}`);
      setUsername(res.data.username);
      setEmail(res.data.email);
    };

    getuserInfo();
    setPic(user.profilePicture);
  }, [user.profilePicture, user._id]);

  const handlesubmit = async (e) => {
    e.preventDefault();

    let updatedUser;

    if (password) {
      updatedUser = {
        userId: user._id,
        username,
        email,
        password,
      };
    } else {
      updatedUser = {
        userId: user._id,
        username,
        email,
      };
    }

    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append('name', filename);
      data.append('file', file);
      updatedUser.profilePicture = filename;
      try {
        await Axios.post('/upload', data);
      } catch (err) {}
    }

    try {
      await Axios.put(`/users/${user._id}`, updatedUser);

      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  // const publicFolder = 'http://localhost:5000/images/';
  const publicFolder = 'https://api-blog.damina.org/images/';

  let myfile;

  if (file === null) {
    myfile = `${publicFolder + pic}`;
  } else {
    myfile = `${URL.createObjectURL(file)}`;
  }

  const handleDelete = async () => {
    await Axios.delete(`/users/${user._id}`);
  };

  return (
    <div>
      <div className='settingsTitle'>
        <span className='settingsUpdateTitle'>Update Account</span>
        <span className='settingsDeleteTitle' onClick={handleDelete}>
          <i className='delete-icon'>
            <FaTrashAlt />
          </i>
          Delete Account
        </span>
      </div>
      <form className='settingsForm' onSubmit={handlesubmit}>
        <label>Profile Picture</label>
        <div className='settingsProfilePic'>
          {/* <img src={file ? URL.createObjectURL(file) : user.profilePicture} alt='' /> */}
          {pic && <img src={myfile} alt='' />}
          <label htmlFor='fileInput'>
            <i className='settingProfileIcon'>
              <FaUserEdit />
            </i>
          </label>
          <input
            type='file'
            id='fileInput'
            style={{ display: 'none' }}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <label>Username</label>
        <input
          type='text'
          name=''
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label>Email</label>
        <input
          type='email'
          name=''
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Password</label>
        <input
          type='password'
          name=''
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type='submit' className='button'>
          Update
        </button>
      </form>

      {success && (
        <span style={{ color: 'green', textAlign: 'center' }}>
          Your Account Profile Updated Sccessfully
        </span>
      )}
    </div>
  );
}

export default Settings;
