import './App.css';

import { Routes, Route } from 'react-router-dom';
import Home from './pages/Frontend/Home.jsx';
import Post from './pages/Frontend/Post.jsx';
import Login from './pages/Backend/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import { useContext } from 'react';
import { Context } from './context/Context';

function App() {
  const { user } = useContext(Context);
  return (
    <Routes>
      <Route exact path='/blog' element={<Home />} />
      <Route path='/blog/post/:postId' element={<Post />} />
      <Route path='/blog/v1/login-12X87' element={ <Login />} />

      <Route
        path='/blog/v1/dashboard'
        element={user ? <Dashboard /> : <Home />}
      />
    </Routes>
  );
}

export default App;
