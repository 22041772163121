import React, { useState } from 'react';
import './Pagination.css';

function Pagination({
  data,
  RenderComponent,
  title,
  pageLimit,
  dataLimit,
  onDelete,
  handlePage,
  handleBlogTobeEdited,
}) {
  const [pages] = useState(Math.round(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <>
      {/* show the posts, 10 posts at a time */}
      <>
        {getPaginatedData().map((d, idx) => (
          <RenderComponent
            key={idx}
            blog={d}
            onDelete={onDelete}
            handlePage={handlePage}
            handleBlogTobeEdited={handleBlogTobeEdited}
          />
        ))}
      </>

      {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
    */}
      <tr className='pagination'>
        {/* previous button */}
        <td
          onClick={goToPreviousPage}
          className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
        >
          prev
        </td>

        {/* show page numbers */}
        {getPaginationGroup().map((item, index) => (
          <td
            key={index}
            onClick={changePage}
            className={`paginationItem ${
              currentPage === item ? 'active' : null
            }`}
          >
            <span>{item}</span>
          </td>
        ))}

        {/* next button */}
        <td
          onClick={goToNextPage}
          className={`next ${currentPage === pages ? 'disabled' : ''}`}
        >
          next
        </td>
      </tr>
    </>
  );
}

export default Pagination;
