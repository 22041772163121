import React, { useState } from 'react';

import './Dashboard.css';
import DashboardColumn from './MainColumn/DashboardColumn';
import Sidebar from './Sidebar/Sidebar';

function Dashboard() {
  const [page, setPage] = useState('BlogList');
  const [blogEditID, setBlogEditID] = useState('');

  const handlePage = (currentPage) => {
    setPage(currentPage);
  };

  const handleBlogTobeEdited = (blogID) => {
    setBlogEditID(blogID);
  };

  return (
    <div className='container dash-border'>
      <Sidebar handlePage={handlePage} />
      <DashboardColumn
        activePage={page}
        handlePage={handlePage}
        handleBlogTobeEdited={handleBlogTobeEdited}
        blogEditID={blogEditID}
      />
    </div>
  );
}

export default Dashboard;
