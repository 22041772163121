import { Axios } from '../../config';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './CategoriesButton.css';
import { Link } from 'react-router-dom';

function CategoriesButton() {
  const [cats, setCats] = useState([]);

  useEffect(() => {
    const getCats = async () => {
      const res = await Axios.get('category');

      res.data.unshift({ 'name': 'All' });
      setCats(res.data);
    };

    getCats();
  }, []);

  const [selectedMI, setSelectedMI] = useState('All');

  return (
    <div className='category-nav'>
      <div className='title'>
        <h1>The Latest</h1>
      </div>
      <div className='btn-categories'>
        {cats &&
          cats.slice(0, 10).map((cat, index) => (
            <Link to={`/blog/?cat=${cat.name.toLowerCase()}`} key={index}>
              <div
                key={index}
                className={`btn ${selectedMI === cat.name ? 'active' : ''} `}
                onClick={() => setSelectedMI(cat.name)}
              >
                <span className='noselect'>{cat.name.toLowerCase()}</span>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default CategoriesButton;
