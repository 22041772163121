import React from 'react';
import './LoadMore.css';

function LoadMore({ handleLoad }) {
  return (
    <div className='load-more-btn' onClick={handleLoad}>
      <span className='button pulse'>Load More</span>
    </div>
  );
}

export default LoadMore;
