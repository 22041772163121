import React, { useEffect, useState } from 'react';
import { Axios } from '../../../config';
import Pagination from './CatPagination';
import CatList from './CatList';
import './CatList.css';
import CatSearchbar from './CatSearchbar';

function CategoryList() {
  const [categories, setCategories] = useState(null);
  const [searchResult, setSearchResult] = useState([]);

  const geCategories = async () => {
    try {
      const cats = await Axios.get('/category');
      // setCategories(posts.data);
      return cats.data;
    } catch (err) {
      console.log(err);
      alert('There was an error while retrieving the data');
    }
  };

  useEffect(() => {
    geCategories()
      .then((cats) => {
        setCategories(cats);
        return cats;
      })
      .then((cats) => {
        setSearchResult(cats);
      });
  }, []);

  return (
    <div className='blogList container'>
      <div className='bloglist-title'>Categories</div>

      <div className='blogList-filter'>
        <CatSearchbar posts={categories} setSearchResult={setSearchResult} />
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th scope='col' className=''>
              Category name
            </th>
          </tr>
        </thead>
        <tbody>
          {/* {blogPosts &&
            blogPosts.map((blog, index) => <List key={index} blog={blog} />)} */}
          {categories && categories.length > 0 ? (
            <>
              <Pagination
                data={searchResult}
                RenderComponent={CatList}
                pageLimit={5}
                dataLimit={10}
              />
            </>
          ) : (
            <tr>
              <td>No Categories to display</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CategoryList;
