import React from 'react';
import { Link } from 'react-router-dom';

function Links({ links, handlePage, handleActiveLink1 }) {
  return (
    <ul>
      {links.map((link, index) => {
        return (
          <li
            // className={link.active ? 'active-sidelink' : ''}
            key={index}
            onClick={() => {
              handlePage(link.linkto);
              link.handleLogout && link.handleLogout();
            }}
          >
            <Link to='#'>
              <link.icon />
              {link.text}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default Links;
