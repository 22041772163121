import React, { useContext } from 'react';

import { RiRadioButtonLine } from 'react-icons/ri';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';

import './List.css';
import { Context } from '../../../context/Context';

function List({ blog, onDelete, handlePage, handleBlogTobeEdited }) {
  // const publicFolder = 'http://localhost:5000/images/';
  const publicFolder = 'https://api-blog.damina.org/images/';

  const { user } = useContext(Context);
  return (
    <tr className='blogList-list'>
      <td width='30%' className='blogList-image'>
        <img src={publicFolder + blog.photo} alt='' />
      </td>
      <td width='20%' className='bloglist-status'>
        <div>
          <i
            style={
              blog.status === 'Draft'
                ? { color: '#fb0000' }
                : { color: '#74cc52' }
            }
          >
            <RiRadioButtonLine />
          </i>
          <p> {blog.status} </p>
        </div>
      </td>
      <td width='40%' className='blogList-title'>
        <p> {blog.title} </p>
      </td>
      <td width='10%' className='blogList-controls'>
        <span
          className='edit'
          onClick={function () {
            handlePage('EditBlog');
            handleBlogTobeEdited(blog._id);
          }}
        >
          <i>
            <FaEdit />
          </i>
        </span>
        <span
          className='delete'
          onClick={() => onDelete(blog._id, user.username)}
        >
          <i>
            <FaTrashAlt />
          </i>
        </span>
      </td>
    </tr>
  );
}

export default List;
