import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../context/Context';
import './Navbar.css';

function Navbar() {
  const { user } = useContext(Context);
  const [pic, setPic] = useState(null);

  useEffect(() => {
    setPic(user.profilePicture);
  }, [user.profilePicture]);

  // const publicFolder = 'http://localhost:5000/images/';
  const publicFolder = 'https://api-blog.damina.org/images/';

  return (
    <div className='dashboard-nav'>
      <div className='title'>
        <h2>Dashboard</h2>
        <span>Manage your blog posts.</span>
      </div>

      <div className='dashboard-profile'>
        <div className='dashboard-prof-pic'>
          <img src={pic && publicFolder + pic} alt='' />
        </div>
        <div className='dashboard-prof-name'>
          <h5>{user.username}</h5>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
