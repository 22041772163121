import React, { useState, useEffect } from 'react';
import './Home.css';
import Topnav from '../../Includes/Topnav/Topnav';
import FeaturedPostsList from '../../components/Post/FeaturedPostsList/FeaturedPostsList';

import MainFeaturedPost from '../../components/Post/MainFeaturedPost/MainFeaturedPost';
import CategoriesButton from '../../components/CategoriesButton/CategoriesButton';
import BlogPost from '../../components/Post/BlogPost/BlogPost';
import LoadMore from '../../components/Button/LoadMore';
import Newsletter from '../../components/Newsletter/Newsletter';
import Footer from '../../Includes/Footer/Footer';

import { Axios } from '../../config';
import { useLocation } from 'react-router-dom';

function Home() {
  const [posts, setPosts] = useState([]);
  const [visible, setVisible] = useState(4);

  const { search } = useLocation();

  //set state to manage query here - add to end of the url

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await Axios.get('posts' + search);
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);

  const handleLoad = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  //get 6 featured posts - sort by date - 1 main featured post & 5 side bar list of featured posts
  const featuredPosts =
    posts &&
    posts.filter((post) => {
      if (post.featured === 'true' && post.status === 'Published') {
        return true;
      }
      return false;
    });
  //get categories - 5 total

  return (
    <div>
      <Topnav />
      <div className='container featured-posts'>
        <FeaturedPostsList />
        <MainFeaturedPost post={featuredPosts[0]} />
      </div>

      <div className='container'>
        <CategoriesButton />
      </div>

      <div className='container blog-posts'>
        {posts.slice(0, visible).map((post) => (
          post.status === 'Published' &&  <BlogPost post={post} key={post._id} />
        ))}
      </div>
      <div className='container'>
        <LoadMore handleLoad={handleLoad} />
      </div>
      <div className='container'>
        <Newsletter />
      </div>
      <div className='container'>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
