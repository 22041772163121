import React, { useState, useEffect } from 'react';
import { BsFillJournalBookmarkFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Axios } from '../../../config';
import './FeaturedPostsList.css';

function FeaturedPostsList() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await Axios.get('posts');
      setPosts(res.data);
    };

    fetchPosts();
  }, []);

  const featuredPosts =
    posts &&
    posts.filter((post) => {
      if (post.featured === 'true' && post.status === 'Published') {
        return true;
      }
      return false;
    });

  const removedPost = featuredPosts && featuredPosts.shift();

  return (
    <div className='featured-post-1'>
      <div className='featured-title'>Featured Posts</div>
      <ul className='featured-posts-list'>
        {featuredPosts &&
          featuredPosts.slice(0, 5).map((post, index) => (
            <li key={index}>
              <Link to={`/blog/post/${post._id}`}>
                <span>
                  <BsFillJournalBookmarkFill />
                </span>
                {post && post.title}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default FeaturedPostsList;
