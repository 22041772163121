import React from 'react';
import moment from 'moment';
import { BsEyeglasses } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import './BlogPost.css';

function BlogPost({ post }) {
  function readingTime() {
    const text = post && post.desc;
    const wpm = 225;
    const words = text && text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);

    return time;
  }

  const readTime = readingTime();
  // const publicFolder = 'http://localhost:5000/images/';
  const publicFolder = 'https://api-blog.damina.org/images/';

  // const contentState = convertFromRaw(JSON.parse(post && post.desc));
  // const editorState = EditorState.createWithContent(contentState);

  return (
    <div className='blog-post'>
      <div className='blog-pic'>
        {post && (
          <img src={publicFolder + post.photo} alt='' />
        )}
      </div>
      <div className='blog-content'>
        <h6 className='blog-post-category'>
          {post.categories &&
            post.categories
              .slice(0, 4)
              .map((category, index) => (
                <span key={index}>{category.value}</span>
              ))}
        </h6>
        <Link to={`/blog/post/${post._id}`}>
          <h2 className='blog-post-title'>{post && post.title}</h2>
        </Link>
        <div className='blog-post-content'>
          {post && (
            <Editor
              editorState={EditorState.createWithContent(
                convertFromRaw(JSON.parse(post && post.desc))
              )}
              readOnly={true}
            />
          )}
        </div>
        <div className='blog-date-read'>
          <p className='blog-date'>
            {moment(post.updatedAt).format('MMMM Do YYYY')}
          </p>
          <div className='blog-min-read'>
            <span>
              <BsEyeglasses /> <p>{readTime} min read</p>
            </span>
          </div>
        </div>
        <div className='blog-author-name'>
          By
          <Link to={`/blog/?username=${post && post.username}`}>
            <span>{post && post.username}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
