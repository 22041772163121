import React from 'react';
import { MdOutlineSearch } from 'react-icons/md';
import './Searchbar.css';

const Searchbar = ({ posts, setSearchResult }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSearchChange = (e) => {
    if (!e.target.value) return setSearchResult(posts);

    const resultsArray = posts.filter(
      (post) =>
        post.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        post.status.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setSearchResult(resultsArray);
  };

  return (
    <header className='searchHeader'>
      <form className='search' onSubmit={handleSubmit}>
        <input
          type='text'
          className='search__input'
          id='search'
          onChange={handleSearchChange}
        />
        <button className='search__button'>
          <MdOutlineSearch />
        </button>
      </form>
    </header>
  );
};

export default Searchbar;
