import React, { useEffect, useState } from 'react';
import './BlogList.css';
import List from './List';
import { Axios } from '../../../config';
import Pagination from './Pagination';
import Searchbar from './Searchbar';

function BlogList({ handlePage, handleBlogTobeEdited }) {
  const [blogPosts, setBlogPosts] = useState(null);
  const [searchResult, setSearchResult] = useState([]);

  const getBlogPosts = async () => {
    try {
      const posts = await Axios.get('/posts');
      // setBlogPosts(posts.data);
      return posts.data;
    } catch (err) {
      console.log(err);
      alert('There was an error while retrieving the data');
    }
  };

  useEffect(() => {
    // getBlogPosts();
    getBlogPosts()
      .then((posts) => {
        setBlogPosts(posts);
        return posts;
      })
      .then((posts) => {
        setSearchResult(posts);
      });
  }, []);

  const onDelete = async (id, userName) => {
    try {
      await Axios.delete(`posts/${id}`, { data: { username: userName } }).then(
        () => {
          getBlogPosts();
            window.location.replace('https://news.damina.org/blog/v1/dashboard');
        }
      );
    } catch (err) {}
  };

  return (
    <div className='blogList container'>
      <div className='bloglist-title'>Blog List</div>
      <div className='blogList-filter'>
        <Searchbar posts={blogPosts} setSearchResult={setSearchResult} />
      </div>
      {/* {blogPosts && blogPosts.map((blog, index) => <List key={index} />)} */}

      <table className='table'>
        <thead>
          <tr>
            <th scope='col' className='bloglist-featuredImage'>
              Featured Image
            </th>
            <th scope='col' className='bloglist-status'>
              Status
            </th>
            <th scope='col' className='bloglist-blogtitle'>
              Title
            </th>
            <th scope='col' className='bloglist-manage'>
              <span>Edit</span> <span>Delete</span>{' '}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* {blogPosts &&
            blogPosts.map((blog, index) => <List key={index} blog={blog} />)} */}
          {blogPosts && blogPosts.length > 0 ? (
            <>
              <Pagination
                // data={blogPosts}
                data={searchResult}
                RenderComponent={List}
                title='Posts'
                pageLimit={5}
                dataLimit={10}
                onDelete={onDelete}
                handlePage={handlePage}
                handleBlogTobeEdited={handleBlogTobeEdited}
              />
            </>
          ) : (
            <tr>
              <td>No Posts to display</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BlogList;
