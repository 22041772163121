import React from 'react';
// import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-minimal'>
      Copyright © 2022 Damina
      <span>|</span>
      <a href='https://damina.org/pages/terms.html#privacypolicy2'>Privacy</a>
      <span>|</span>
      <a href='https://damina.org/pages/terms.html'>Terms</a>
    </div>
  );
}

export default Footer;
