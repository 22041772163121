import React from 'react';
import './MainFeaturedPost.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { BsEyeglasses } from 'react-icons/bs';
// import { stateToHTML } from 'draft-js-export-html';

function MainFeaturedPost({ post }) {
  // console.log(post && post.desc);
  function readingTime() {
    const text = post && post.desc;
    const wpm = 225;
    const words = text && text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);

    return time;
  }

  const readTime = readingTime();

  // const publicFolder = 'http://localhost:5000/images/';
  const publicFolder = 'https://api-blog.damina.org/images/';

  return (
    <div className='featured-post-2'>
      <div className='featured-pic'>
        {post && (
          <img src={publicFolder + post.photo} alt='' />
        )}
        <p>Featured Post</p>
      </div>
      <div className='featured-content'>
        <h6 className='post-category'>
          {post &&
            post.categories
              .slice(0, 4)
              .map((category, index) => (
                <span key={index}>{category.value}</span>
              ))}
        </h6>
        <h2 className='post-title'>
          <Link to={`/blog/post/${post && post._id}`}>
            {post && post.title}
          </Link>
        </h2>
        <div className='post-content'>
          {post && (
            <Editor
              editorState={EditorState.createWithContent(
                convertFromRaw(JSON.parse(post && post.desc))
              )}
              readOnly={true}
            />
          )}
        </div>
        <div className='date-read'>
          <p className='date'>
            {moment(post && post.updatedAt).format('MMMM Do YYYY')}{' '}
          </p>
          <div className='min-read'>
            <span>
              <BsEyeglasses /> <p> {readTime} min read</p>
            </span>
          </div>
        </div>
        <div className='author-name'>
          By
          <Link to={`/blog/?username=${post && post.username}`}>
            <span> {post && post.username} </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainFeaturedPost;
