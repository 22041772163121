import React, { useEffect, useState } from 'react';
import AddBlog from '../AddBlog/AddBlog';
import './DashboardColumn.css';
import Navbar from './Navbar';

import BlogList from '../BlogList/BlogList';
import EditBlog from '../EditBlog/EditBlog';
import Settings from '../Settings/Settings';

import { Axios } from '../../../config';
import CategoryList from '../Category/CategoryList';
import AddCategory from '../Category/AddCategory';

function DashboardColumn({
  activePage,
  handlePage,
  handleBlogTobeEdited,
  blogEditID,
}) {
  const [dbCategories, setDbCategories] = useState(null);

  const getCategories = async () => {
    try {
      const res = await Axios.get('/category');

      const blogCategories = res.data.map((cat) => {
        let str = cat.name;
        const str2 = str.charAt(0).toLowerCase() + str.slice(1);
        return {
          value: cat.name,
          label: str2,
        };
      });

      setDbCategories(blogCategories);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  // const handleEditPage = () => {};

  return (
    <div className='dashboard'>
      <Navbar />
      <div className='dashboard-content'>
        {activePage === 'BlogList' ? (
          <BlogList
            handlePage={handlePage}
            handleBlogTobeEdited={handleBlogTobeEdited}
          />
        ) : activePage === 'AddBlog' ? (
          <AddBlog cats={dbCategories} />
        ) : activePage === 'EditBlog' ? (
          <EditBlog blogEditID={blogEditID} cats={dbCategories} />
        ) : activePage === 'CategoriesList' ? (
          <CategoryList />
        ) : activePage === 'AddCategory' ? (
          <AddCategory />
        ) : activePage === 'Settings' ? (
          <Settings />
        ) : (
          <BlogList />
        )}
      </div>
    </div>
  );
}

export default DashboardColumn;
