import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg';
import './Topnav.css';

function Topnav() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='container nav-conatiner'>
      <div className='Navbar'>
        <div className='nav-logo'>
          <a href="https://damina.org">
          <img src={logo} alt='' />
          </a>
        </div>
        <ul className={`nav-items ${isOpen && 'open'}`}>
          <li>
            <a href='https://damina.org'>Home</a>
          </li>
          <li>
            <a style={{color: '#c0d836'}} href='https://news.damina.org/blog'>Blog</a>
          </li>
        </ul>
        <div
          className={`nav-toggle ${isOpen && 'open'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className='bar'></div>
        </div>
      </div>
    </div>
  );
}

export default Topnav;
