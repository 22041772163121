import React, { useContext } from 'react';
import logo from '../../../images/logo.svg';

import './Sidebar.css';

import {
  MdLogout,
  MdLibraryBooks,
  MdSettings,
  MdViewList,
  MdFeaturedPlayList,
  MdChecklistRtl,
} from 'react-icons/md';
import Links from './Links';
import { Context } from '../../../context/Context';

function Sidebar({ handlePage }) {
  const { dispatch } = useContext(Context);

  const links1 = [
    {
      text: 'Blog List',
      icon: MdViewList,
      active: true,
      linkto: 'BlogList',
    },
    {
      text: 'Add Post',
      icon: MdLibraryBooks,
      active: false,
      linkto: 'AddBlog',
    },
    {
      text: 'Categories',
      icon: MdFeaturedPlayList,
      active: true,
      linkto: 'CategoriesList',
    },
    {
      text: 'Add Category',
      icon: MdChecklistRtl,
      active: false,
      linkto: 'AddCategory',
    },
  ];

  const links2 = [
    {
      text: 'Settings',
      icon: MdSettings,
      active: false,
      linkto: 'Settings',
    },
    {
      text: 'Logout',
      icon: MdLogout,
      linkto: '/blog/login-1287',
      handleLogout: () => {
        dispatch({ type: 'LOGOUT' });
        window.location.replace('https://news.damina.org/blog/');
      },
    },
  ];

  return (
    <div className='sidebar'>
      <div className='brand'>DAMINA</div>
      <div className='side-links'>
        <Links links={links1} handlePage={handlePage} />
        <Links links={links2} handlePage={handlePage} />
      </div>
      <div className='side-logo'>
        <img src={logo} alt='' />
        <p>Version 1.0.0</p>
      </div>
    </div>
  );
}

export default Sidebar;
