import React, { useState, useRef, useEffect } from 'react';
import './Newsletter.css';
import axios from 'axios';

function Newsletter() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const timerRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newEmail = {
      'email': email,
    };

    try {
      await axios.post('https://newsletter-api.damina.org', newEmail);
      setEmail('');
      setSuccess(true);
      timerRef.current = setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (err) {}
  };

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <form className='newsletter-box' onSubmit={handleSubmit}>
      <div className='newsletter-title'>
        <h3>Subscribe to our newsletter for social resources</h3>
        <p>
          We will keep you in the loop on our best advice and strategies for
          social media marketing and growing a small business. Become a better
          social media marketer. Get social media resources and tips in your
          inbox weekly.
        </p>
      </div>
      <div className='newsletter-input'>
        <label htmlFor='newsletter-email'>
          <h3>Business Email</h3>
        </label>
        <input
          type='email'
          name='email'
          id='newsletter-email'
          value={email}
          onChange={handleEmailChange}
        />
        {success && (
          <div className='success-message'>
            You have successfully subscribed 👏.
          </div>
        )}
        <p className='newsletter-notice'>
          Damina is committed to your privacy. By submitting this form, you
          acknowledge Damina uses your information in accordance with its
          Privacy Policy. You may unsubscribe from our communications at any
          time. To opt out, please email info@damina.org.
        </p>
        <button className='button'>Sign Me Up</button>
      </div>
    </form>
  );
}

export default Newsletter;
